/* eslint-disable @typescript-eslint/no-explicit-any */
import { initializeAnalytics, logEvent } from "firebase/analytics";
import type { FirebaseOptions } from "firebase/app";
import { initializeApp } from "firebase/app";
import { getPerformance, trace } from "firebase/performance";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { WebAppBrand, WebAppEnvironment } from "shared/models";

(function (global: typeof globalThis) {
    const tracking = {
        getConfig,
        initializeApp,
        fetchAndActivate,
        getRemoteConfig,
        getValue,
        logEvent,
        initializeAnalytics,
        getPerformance,
        trace
    };
    (<any>global).trackingFbase = tracking;
}.call(this, global));

function getConfig(webAppBrand: WebAppBrand, webAppEnvironment: WebAppEnvironment): FirebaseOptions {
    switch (webAppBrand) {
        case WebAppBrand.Telmore: {
            switch (webAppEnvironment) {
                case WebAppEnvironment.Development:
                    return fbaseConfigTelmoreDevelopment;

                case WebAppEnvironment.Production:
                    return fbaseConfigTelmoreProduction;
            }
            break;
        }
        case WebAppBrand.YouSee: {
            switch (webAppEnvironment) {
                case WebAppEnvironment.Development:
                    return fbaseConfigYouSeeDevelopment;

                case WebAppEnvironment.Production:
                    return fbaseConfigYouSeeProduction;
            }
            break;
        }
    }
}

const fbaseConfigTelmoreDevelopment = {
    apiKey: "AIzaSyCLOcD0NzijFIlbPTlhcvBiN8JfZIgbNog",
    authDomain: "telmore-musik.firebaseapp.com",
    databaseURL: "https://telmore-musik.firebaseio.com",
    projectId: "telmore-musik",
    storageBucket: "telmore-musik.appspot.com",
    messagingSenderId: "716868232507",
    appId: "1:716868232507:web:8ccecc51fc79f8a2e3c31e",
    measurementId: "G-JXD7MBSDVL"
};

const fbaseConfigTelmoreProduction = {
    apiKey: "AIzaSyCLOcD0NzijFIlbPTlhcvBiN8JfZIgbNog",
    authDomain: "telmore-musik.firebaseapp.com",
    databaseURL: "https://telmore-musik.firebaseio.com",
    projectId: "telmore-musik",
    storageBucket: "telmore-musik.appspot.com",
    messagingSenderId: "716868232507",
    appId: "1:716868232507:web:abc75557269c3feae3c31e",
    measurementId: "G-QPST4B7BFD"
};

const fbaseConfigYouSeeDevelopment = {
    apiKey: "AIzaSyCeY8juz7meGQRw8Qsw4qHjFaoX3I-wYEI",
    authDomain: "yousee-musik.firebaseapp.com",
    databaseURL: "https://yousee-musik.firebaseio.com",
    projectId: "yousee-musik",
    storageBucket: "yousee-musik.appspot.com",
    messagingSenderId: "346906455770",
    appId: "1:346906455770:web:ee41721332ef9309ab3fe3",
    measurementId: "G-BPBQ6LF2J3"
};

const fbaseConfigYouSeeProduction = {
    apiKey: "AIzaSyCeY8juz7meGQRw8Qsw4qHjFaoX3I-wYEI",
    authDomain: "yousee-musik.firebaseapp.com",
    databaseURL: "https://yousee-musik.firebaseio.com",
    projectId: "yousee-musik",
    storageBucket: "yousee-musik.appspot.com",
    messagingSenderId: "346906455770",
    appId: "1:346906455770:web:e6f324d632258a2eab3fe3",
    measurementId: "G-W882038TCG"
};
